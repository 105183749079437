import React from "react"
import Layout from '../components/layout'; 
import contactStyles from '../styles/contact.module.scss'
import image from '../images/contact.jpg'
import image2 from '../images/telefono.jpg'

const ContactPage = () => {
    return(
        <Layout>
            <div className={contactStyles.contenido1}>
                <h1 className={contactStyles.titulo}>Contacto</h1>
                <div className={contactStyles.row}>
                    <div className={contactStyles.column}>
                        <h3>¡Ponte en contacto con nosotros!</h3>
                        <h4 className={contactStyles.mail}><a href="mailto:contacto@control-integral.com" className={contactStyles.mailLink}>contacto@control-integral.com</a></h4> 
                        <div className={contactStyles.numerosDiv}>
                            <img className={contactStyles.image2} src={image2} alt="imagen contacto" />
                            <h4 className={contactStyles.numeros}>(442) 193-2589</h4>
                            <h4 className={contactStyles.numeros}>(55) 30.00.19.50 Ext 120</h4>
                            <h4 className={contactStyles.numeros}>(55) 41.64.58.22</h4>
                        </div>
                        <div className={contactStyles.textoDiv}>
                            <h4 className={contactStyles.contactoTexto}>&nbsp;&nbsp;&nbsp;Av. Ignacio Zaragoza # 279, Int 207 Centro Queretaro, Qro. 76000</h4>
                            <h4 className={contactStyles.contactoTexto}><span>Rio Lerma # 94, Piso 3 Cuauhtémoc, CDMX, 06500 &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp;</span></h4>
                            <h4 className={contactStyles.contactoTexto}><span>Rodolfo Gaona # 3, Piso 5 Lomas de Sotelo, CDMX, 11200</span></h4>
                        </div>
                    </div>
                    <div className={contactStyles.column}>
                        <img className={contactStyles.image} src={image} alt="imagen contacto" />
                    </div>
                </div>

            </div>
        </Layout>
    ) 
}
export default ContactPage;
